<template>
  <Layout :footer-style="1">
    <div class="container">
      <Breadcrumb root-url="/" parent-url="首页" current-url="邮件安全" style="border: none;"/>
    </div>

    <div class="rn-service-area rn-section-gapBottom pt--100 bg-color-light area-2">
      <div class="container">
        <div class="product">
          <img class="pic" src="@/assets/images/product/softnext.png" />
          <div class="about">
            <div class="title">守内安邮件安全解决方案</div>
            <div class="content">
							提供SPAM SQR 邮件安全网关、Mail SQR Expert 邮件审计管理、Mail Archiving Expert 电子邮件归档管理等邮件安全解决方案。
						</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Start Service Area -->
    <div class="rn-service-area rn-section-gapBottom pt--60 area-2">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title" data-aos="fade-up">
              <h3 class="title color-gray text-center">SPAM SQR 邮件安全网关</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center" data-aos="fade-up">
            <div class="company-info">
							新一代邮件过滤系统，高效过滤普通垃圾邮件与高危钓鱼，病毒邮件。在全面安全保护的同时提供差异行为管理功能。即可满足用户自主管理放行邮件，亦可管控恶意邮件流窜。同时垃圾与威胁邮件双核过滤技术，拥有完善的特征库、指纹库和恶意网址数据库云端差分更新技术，搭载流量控制防御引擎、社交工程侦测引擎，附件内容分析引擎，有效缩短 0day 攻击的风险，提升系统整体拦截效能。网关架构于企业邮件服务器前，有效降低各式邮件攻击风险，如 BEC，垃圾/广告邮件、钓鱼邮件(社交工程)、伪造攻击邮件、病毒邮件、携带病毒附件邮件等。
            </div>
          </div>
        </div>

        <Separator style="margin: 80px 0;"/>

        <div class="row">
          <div class="col-lg-12">
            <div class="section-title" data-aos="fade-up">
              <h3 class="title color-gray text-center">Mail SQR Expert 邮件审计管理</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center" data-aos="fade-up">
            <div class="company-info">
							<div>邮件是企业间数据传递的主要渠道，也是大量敏感信息储存的媒介，如何有效管理邮件（邮件审计）已成为传输中的数据泄露防护（Data Loss Prevention，简称 DLP）的重要课题。企业在管理邮件时，首要任务为过滤病毒类邮件、审核敏感信息邮件，例如：信用卡号、电话、地址等，并预防员工因无心操作而造成的敏感信息泄露。最棘手的问题则是如何将不断成长累积的邮件数据，以符合法规的方式长期保存，并能在需要调查或举证时，快速找到关键邮件。</div>
							<div>Mail SQR Expert 邮件审计管理专家，提供企业通道式 DLP 完整的解决方案，以模块化的扩充功能设计，解决 DLP 加密的难题，并可深度整合第三方文件加解密或文件分级系统，提升成企业级内容感知型 DLP。功能设计遵循 3A 的管理循环，从分析 (Analysis) 收集判断，找到需管理的条件付诸行动 (Action)，最后借由审计 (Audit) 检查实际效果，找出需要再分析的情况，重新 3A 循环。</div>
						</div>
          </div>
        </div>

        <Separator style="margin: 80px 0;"/>

        <div class="row">
          <div class="col-lg-12">
            <div class="section-title" data-aos="fade-up">
              <h3 class="title color-gray text-center">Mail Archiving Expert 电子邮件归档管理</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center" data-aos="fade-up">
            <div class="company-info">
							<div>企业在面对电子邮件管理时，最棘手的问题不外乎是该如何管理经年累月不断成长的电子邮件数据，能在符合法规的条件下长期保存，也能在需要举证时，快速搜寻找到关键的电子邮件。</div>
							<div>Mail Archiving Expert 电子邮件归档管理除了能够解决即时邮件归档，防止使用者误删邮件外，也提供完整的邮件生命周期管理，可依年份将邮件自动搬移至不同的储存媒介，也可依部门别保留不同年限的邮件。提供弹性的调阅权限管理，搭配查询结果指定给其它管理人员做再次查询的调阅流程，精确定位出需调查的信件，避免授权过度而看到不应被调阅的信件内容。</div>
						</div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Service Area -->
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import Icon from '@/components/icon/Icon'
import Separator from '@/components/elements/separator/Separator'
import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
import Breadcrumb from '@/components/elements/breadcrumb/Breadcrumb'
export default {
  name: 'Product',
  components: {
    SectionTitle,
    Separator,
    Icon,
    Breadcrumb,
    Layout
  },
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.display-block{
  display: block;
}
.area-2{
  .icon {
    .p-img {
      width: 64px;
      height: 64px;
    }
  }
  .more{
    display: inline-flex;
    color: #2261AA;
    img {
      width: 27px;
      height: 27px;
    }
    .i1{
      margin-left: -4px;
    }
    .i2{
      margin-left: -20px;
    }
  }
}
.product{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  .pic{
    width: 220px;
  }
  .about{
    margin-left: 40px;
    color: #000000;
    .title{
      font-size: 32px;
      font-weight: bold;
    }
    .content{
      margin-top: 12px;
      font-size: 20px;
    }
  }
}
.company-logo{
  width: 320px;
}
.company-info{
  font-size: 24px;
  color: #000000;
  text-align: left;
  margin-top: 20px;
}
</style>
